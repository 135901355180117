import React from 'react';
import './cartitem.css';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { decrementQuantity, deleteItem, incrementQuantity, resetCart } from '../redux/buyzonSlice';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CartItem = () => {
  const dispatch = useDispatch();
  const productData = useSelector((state) => state.buyzon.productData);
  return (
    <div className="cartitem">
      <div className="cart_items">
        <h2>shopping cart</h2>
      </div>
      <div>
        {productData.map((item) => (
          <div key={item.id} className="item_details">
            <div className="item_content">
              <CloseIcon
                onClick={() =>
                  dispatch(deleteItem(item.id)) &
                  toast.error(`${item.name} is removed from your cart`)
                }
                className="close_icon"
              />
              <img className="item_image" src={item.image} alt="prodImg" />
            </div>
            <h2>{item.name}</h2>
            <p>₹{item.price}</p>
            <div className="qty_ctrl">
              <p>Qnt:</p>
              <div className="qnty">
                <span
                  onClick={() =>
                    dispatch(
                      decrementQuantity({
                        id: item.id,
                        name: item.name,
                        image: item.image,
                        price: item.price,
                        quantity: 1,
                        description: item.description,
                      })
                    )
                  }
                >
                  -
                </span>
                {item.quantity}
                <span
                  onClick={() =>
                    dispatch(
                      incrementQuantity({
                        id: item.id,
                        name: item.name,
                        image: item.image,
                        price: item.price,
                        quantity: 1,
                        description: item.description,
                      })
                    )
                  }
                >
                  +
                </span>
              </div>
            </div>
            <p className="subtotal">₹{item.quantity * item.price}</p>
          </div>
        ))}
      </div>
      <button
        onClick={() => dispatch(resetCart()) & toast.error(`The cart is empty`)}
        className="resetcart"
      >
        Reset cart
      </button>
      <NavLink to="/buyzon-ecommerce">
        <button>{`<< go shopping`}</button>
      </NavLink>
      <ToastContainer />
    </div>
  );
}

export default CartItem