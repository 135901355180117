import React from 'react';
import './products.css';
import ProductsCard from './ProductsCard';

const Products = ({products}) => {
  return (
    <div className='products'>
        <div className='header'>
            <h1 className='h1-heading'>Explore Premium Collection</h1>
            <p className='text-heading'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis sapiente quasi qui fugiat reiciendis harum ea repudiandae totam possimus aspernatur in at sed quod corrupti amet nobis, vel laudantium tempora eaque! Optio tempora necessitatibus fugiat eaque, accusantium earum et quam reiciendis eum vero incidunt.</p>
        </div>
        <div className='product_container'>
            {
                products.map((item)=> (
                    <ProductsCard key={Math.random()} product={item}/>
                ))
            }
        </div>
    </div>
  )
}

export default Products