import React, { useEffect, useState } from 'react'
import './productdetails.css';
import { useLocation } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import { useDispatch } from 'react-redux';
import { addToCart } from '../redux/buyzonSlice';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState({});
  const location = useLocation();
  let [baseQty, setBaseQty] = useState(1);
  useEffect(()=> {
    setDetails(location.state.item);
  },[location.state.item]);
  return (
    <div>
      <div className='details_container'>
        <div className='product_container'>
          <img className='product_image' src={details.image} alt='productImg'/>
        </div>
        <div className='content'>
          <div className='product_details'>
            <h2>{details.name}</h2>
            <h4>₹ {details.price}</h4>
            <div key={Math.random()} className="product_Rating">
              {[...Array(details.rating)].map((index) => (
                  <StarIcon key={Math.random()} />
              ))}
              <p>(1 customer review)</p>
            </div>
            <p>{details.description}</p>
            <div>
              <div className='prod_qnty'>
                <p>Quantity</p>
                <div className='qnty_control'>
                  <button onClick={()=> setBaseQty(baseQty === 1 ? (baseQty = 1) : baseQty-1)}>-</button>
                  <span><p>{baseQty}</p></span>
                  <button onClick={()=> setBaseQty(baseQty+1)}>+</button>
                </div>
                <button onClick={()=> dispatch(addToCart({
                    id: details.id,
                    name: details.name,
                    image: details.image,
                    price: details.price,
                    quantity: baseQty,
                    description: details.description,
                  })) & toast.success(`${details.name} is added into the cart`)} className="addBtn" title="Add to Cart">
                  Add To Cart
                </button>
              </div>
            </div>
          </div>
        </div>
    </div>
    <ToastContainer/>
  </div>
  )
}

export default ProductDetails