import React from "react";
import logo from "../assets/logoDark.png";
import { NavLink } from "react-router-dom";
import { IconButton } from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { Avatar } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import HistoryIcon from "@mui/icons-material/History";
import "./navbar.css";
import { useSelector } from "react-redux";

const Navbar = () => {
  const productData = useSelector((state) => state.buyzon.productData);
  const userInfo = useSelector( ( state ) => state.buyzon.userInfo );
  
  return (
    <>
      <input type="checkbox" id="check" className="header-menu" />
      <header>
        <NavLink to="/">
          <img src={logo} alt="logo" style={{ width: "120px" }} />
        </NavLink>
        <div className="search-box">
          <form>
            <input
              type="text"
              placeholder="search"
              autoComplete="on"
              // value={searchTerm}
              // onChange={(e) => setSearchTerm(e.target.value)}
            />
            <IconButton type="submit" className="icon-btn">
              <SearchOutlinedIcon className="icon" />
            </IconButton>
          </form>
        </div>
        <ul>
          <li>
            <div className="user-login-info">
              <NavLink to="/signin" style={{ marginRight: "10px" }}>
                <Avatar src={userInfo ? userInfo.image : ""} />
              </NavLink>
              {userInfo ? (
                <>
                  <h5>Welcome {userInfo.name}</h5>
                  <NavLink to="/history" style={{ marginLeft: "20px" }}>
                    <HistoryIcon style={{ color: "#e5a264" }} />
                  </NavLink>
                </>
              ) : (
                <h5>Welcome Guest!</h5>
              )}
            </div>
          </li>
          <li>
            <NavLink to="/favourite">
              <FavoriteOutlinedIcon style={{ color: "red" }} />
            </NavLink>
          </li>
          <li>
            <div className="cart">
              <NavLink to="/cart">
                <ShoppingCartOutlinedIcon style={{ color: "#224957" }} />
              </NavLink>
              <span>{productData.length}</span>
            </div>
          </li>
        </ul>
        <label htmlFor="check" className="bar">
          <span className="fa fa-bars" id="bars"></span>
          <span className="fa fa-times" id="times"></span>
        </label>
      </header>
    </>
  );
};

export default Navbar;
