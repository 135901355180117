import React from 'react';
import './productscard.css';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addToCart, addToFav } from '../redux/buyzonSlice';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductsCard = ({product}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const _id = product.name;

  const idString = (_id) => {
    return String(_id).toLowerCase().split(" ").join("");
  }

  const rootId = idString(_id);
  
  const handleDetails = () => {
    navigate(`/product/${rootId}`, {
      state: {
        item: product,
      }
    })
  };

  return (
    <div key={Math.random()} className="Product_Container">
      <div key={Math.random()} className="product_Icon">
        <FavoriteIcon className={"productCard__wishlist"} onClick={()=> dispatch(addToFav({
            id: product.id,
            name: product.name,
            image: product.image,
            price: product.price,
            quantity: 1,
            description: product.description,
          })) & toast.success(`${product.name} is added to your favorite`)} />
      </div>
      <div key={Math.random()} className="Product_Image">
        <img onClick={handleDetails} src={product.image} alt="Product" width="100%" height="100%" />
      </div>
      <div key={Math.random()} className="Product_Details">
        <div key={Math.random()} className="product_Name">
          <p>{product.name}</p>
        </div>
        <div key={Math.random()} className="product_Options">
          <div key={Math.random()} className="product_Price">
            <p>₹ {product.price}</p>
          </div>
          <div key={Math.random()} className="product_Rating">
            {[...Array(product.rating)].map((index) => (
                <StarIcon key={Math.random()} />
            ))}
          </div>
        </div>
        <button 
          className="addBtn" 
          title="Add to Cart" 
          onClick={()=> dispatch(addToCart({
            id: product.id,
            name: product.name,
            image: product.image,
            price: product.price,
            quantity: 1,
            description: product.description,
          })) & toast.success(`${product.name} is added into the cart`)}>
          Add To Cart
        </button>
      </div>
      <ToastContainer/>
    </div>
  )
}

export default ProductsCard