import React, { useEffect, useState } from 'react';
import './cart.css';
import { useDispatch, useSelector } from 'react-redux';
import CartItem from '../components/CartItem';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addToHistory, resetCart } from '../redux/buyzonSlice';

const Cart = () => {
  const dispatch = useDispatch();
  const productData = useSelector((state) => state.buyzon.productData);
  const userInfo = useSelector((state) => state.buyzon.userInfo);
  const [totalAmt, setTotalAmt] = useState("");

  useEffect(()=> {
    let price =0;
    productData.map((item)=> {
      price += item.price * item.quantity;
      return price;
    });
    setTotalAmt(price);
  },[productData]);

  const handleCheckout = () => {
    if(userInfo){
      const current = new Date();
      const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
      productData.map((product)=> (
        dispatch(addToHistory({
          orderdate: date,
          customer: userInfo.name,
          shipping: "Lorem ipsum, dolor sit amet consectetur adipisicing elit.",
          id: product.id,
          name: product.name,
          image: product.image,
          price: product.price,
          quantity: product.quantity,
          total: totalAmt,
        }))
      ))
      dispatch(resetCart());
      toast.success(`Items checked out successfully`);
    }else {
      toast.error('Please login to checkout');
    }
  };

  return (
    <div>
      <div className='cart_details'>
        <CartItem/>
        <div className="cart_totals">
          <div className='totals_container'>
            <h2>cart totals</h2>
            <p className='subtotal'>Subtotal: <span>₹ {totalAmt}</span></p>
            <p className='shipping'>Shipping: <span>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</span></p>
          </div><hr/>
          <p className='totalamount'>Total <span>₹ {totalAmt}</span></p>
          <button onClick={handleCheckout} className='checkout_button'>proceed to checkout</button>
        </div>
      </div>
      <ToastContainer/>
    </div>
  )
}

export default Cart;