import React from 'react';
import './favourite.css';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, deleteFav, resetFav } from '../redux/buyzonSlice';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Favourite = () => {
  const dispatch = useDispatch();
  const favData = useSelector((state) => state.buyzon.favData);

  return (
    <div className='fav'>
        <div className='header'>
            <h1 className='h1-heading'>A few of your fovorite things!</h1>
            <p className='text-heading'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis sapiente quasi qui fugiat reiciendis harum ea repudiandae totam possimus aspernatur in at sed quod corrupti amet nobis, vel laudantium tempora eaque! Optio tempora necessitatibus fugiat eaque, accusantium earum et quam reiciendis eum vero incidunt.</p>
        </div>
        <div className='fav_container'>
          {
            favData.map((item) => (
              <div key={Math.random()} className="favorite_Container">
                <div key={Math.random()} className="favorite_Icon">
                  <CloseIcon className={"favoriteCard__wishlist"} onClick={()=> dispatch(deleteFav(item.id)) & toast.error(`${item.name} is removed from your favorite`)}/>
                </div>
                <div key={Math.random()} className="favorite_Image">
                  <img src={item.image} alt="Product" width="100%" height="100%" />
                </div>
                <div key={Math.random()} className="favorite_Details">
                  <div key={Math.random()} className="favorite_Name">
                    <p>{item.name}</p>
                  </div>
                  <div key={Math.random()} className="favorite_Options">
                    <div key={Math.random()} className="favorite_Price">
                      <p>₹ {item.price}</p>
                    </div>
                    <div key={Math.random()} className="favorite_Rating">
                      {[...Array(item.rating)].map((index) => (
                          <StarIcon key={Math.random()} />
                      ))}
                    </div>
                  </div>
                  <button 
                    className="addBtn" 
                    title="Add to Cart" 
                    onClick={()=> dispatch(addToCart({
                      id: item.id,
                      name: item.name,
                      image: item.image,
                      price: item.price,
                      quantity: 1,
                      description: item.description,
                    })) & toast.success(`${item.name} is added into the cart`)}>
                    Add To Cart
                  </button>
                </div>
              </div>
            ))
          }
        </div>
        <button onClick={()=> dispatch(resetFav()) & toast.error(`The favorites are empty`)} className='resetfav'>Reset Favorite</button>
        <ToastContainer/>
    </div>
  )
}

export default Favourite