import React from 'react';
import './orderhistory.css';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteHistory, resetHistory } from '../redux/buyzonSlice';

const OrderHistory = () => {
  const dispatch = useDispatch();
  const historyData = useSelector((state) => state.buyzon.historyData);
  return (
    <div className='history_details'>
      <div className='header'>
        <h1 className='h1-heading'>Your order history!</h1>
        <p className='text-heading'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Facilis sapiente quasi qui fugiat reiciendis harum ea repudiandae totam possimus aspernatur in at sed quod corrupti amet nobis, vel laudantium tempora eaque! Optio tempora necessitatibus fugiat eaque, accusantium earum et quam reiciendis eum vero incidunt.</p>
      </div>
      {
        historyData.map((doc)=> (
          <>
          <div className="details">
            <CloseIcon onClick={()=> dispatch(deleteHistory(doc.id)) & toast.error(`One history is removed from your order table`)} className='close_icon'/>
            <div className="history_table">
              <h2>Ordered On:- {doc.orderdate}</h2>
              <table className='Order_Table' key={Math.random()}>
                  <thead>
                      <tr key={Math.random()}>
                          <th>Title</th>
                          <th>Price</th>
                          <th>Quantity</th>
                          <th>Total Price</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr key={Math.random()}>{doc.name}
                        <td>₹ {doc.price}</td>
                        <td>{doc.quantity}</td>
                        <td>₹ {doc.price * doc.quantity}</td>
                    </tr>
                  </tbody>
                  <tr key={Math.random()}  className='OrderTable_totalPrice'>
                      <td>₹ {doc.total}</td>
                  </tr>
              </table>
            </div>
          </div>
          </>
        ))
      }
      <button onClick={()=> dispatch(resetHistory()) & toast.error(`The history is empty`)} className='reset_history'>Reset History</button>
      <ToastContainer/>
    </div>
  )
}

export default OrderHistory