import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "buyzon-aac39.firebaseapp.com",
  projectId: "buyzon-aac39",
  storageBucket: "buyzon-aac39.appspot.com",
  messagingSenderId: "343185754995",
  appId: "1:343185754995:web:beb89cb4fee33b15fd9306",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);