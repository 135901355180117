import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    productData: [],
    favData: [],
    historyData: [],
    userInfo: null,
};

export const buyzonSlice = createSlice({
    name: "buyzon",
    initialState,
    reducers: {
        addToFav: (state, action) => {
            const item = state.favData.find((item)=> item.id === action.payload.id)
            if(!item) {
                state.favData.push(action.payload);
            }
        },
        deleteFav: (state, action) => {
            state.favData = state.favData.filter(
                (item) => item.id !== action.payload
            );
        },
        resetFav: (state) => {
            state.favData = [];
        },
        addToCart: (state, action) => {
            const item = state.productData.find((item)=> item.id === action.payload.id)
            if(item) {
                item.quantity += action.payload.quantity;
            }else {
                state.productData.push(action.payload);
            }
        },
        deleteItem: (state, action) => {
            state.productData = state.productData.filter(
                (item) => item.id !== action.payload
            );
        },
        resetCart: (state) => {
            state.productData = [];
        },
        incrementQuantity: (state, action) => {
            const item = state.productData.find(
                (item) => item.id === action.payload.id
            );
            if(item){
                item.quantity++;
            }
        },
        decrementQuantity: (state, action) => {
            const item = state.productData.find(
                (item) => item.id === action.payload.id
            );
            if(item.quantity === 1){
                item.quantity = 1;
            }else {
                item.quantity--;
            }
        },
        addUser: (state, action) => {
            state.userInfo = action.payload;
        },
        removeUser: (state) => {
            state.userInfo = null;
        },
        addToHistory: (state, action) => {
            state.historyData.push(action.payload);
        },
        deleteHistory: (state, action) => {
            state.historyData = state.historyData.filter(
                (item) => item.id !== action.payload
            );
        },
        resetHistory: (state) => {
            state.historyData = [];
        },
    },
});

export const { 
    addToFav, 
    deleteFav, 
    resetFav, 
    addToCart, 
    deleteItem, 
    resetCart, 
    incrementQuantity, 
    decrementQuantity, 
    addUser, 
    removeUser,
    addToHistory,
    deleteHistory,
    resetHistory } = buyzonSlice.actions;
    
export default buyzonSlice.reducer;