import React, { useEffect, useState } from 'react';
import './signin.css';
import { 
    getAuth, 
    signInWithPopup, 
    GoogleAuthProvider, 
    signOut } from "firebase/auth";
import { useDispatch, useSelector } from 'react-redux';
import { addUser, removeUser } from '../redux/buyzonSlice';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state.buyzon.userInfo);
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const [signIn, setSignIn] = useState(false);

  useEffect(()=> {
    if(userInfo) {
      setSignIn(true);
    }else {
      setSignIn(false);
    }
  },[userInfo]);

  const handleGoogleLogin = (e) => {
      e.preventDefault();
      signInWithPopup(auth, provider).then((result) => {
          const user = result.user;
          dispatch(addUser({
              _id: user.uid,
              name: user.displayName,
              email: user.email,
              image: user.photoURL,
          }));
      setTimeout(()=>{
          navigate("/");
      },1500)
      }).catch((error)=> {
          console.log(error);
      })
  };

  const handleGoogleSignOut = () => {
      signOut(auth).then(()=> {
          dispatch(removeUser());
      }).catch ((error) => {
          console.log(error);
      });
  }
  return (
    <div className='signin_container'>
      {signIn ? (<button onClick={()=> handleGoogleSignOut()}>Sign Out</button>) : (
        <div className='signin'>
          <div onClick={handleGoogleLogin} className="signin-info">
            <img src='https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-webinar-optimizing-for-success-google-business-webinar-13.png' alt='googleLogo' style={{width:'40px'}} />
            <span>Sign in with Google</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default SignIn