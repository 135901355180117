const productData = ()=> {
  return [
    {
      id: 1,
      name: "Fjallraven - Foldsack No. 1 Backpac...",
      image:
        "https://fakestoreapi.com/img/81fPKd-2AYL._AC_SL1500_.jpg",
      category: "gents",
      price: 1099,
      rating: 5,
      timeLeft: 27,
      totalSales: 7479,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 2,
      name: "SanDisk SSD PLUS 1TB Internal SSD -...",
      image:
        "https://fakestoreapi.com/img/61U7T1koQqL._AC_SX679_.jpg",
      category: "electronics",
      price: 699,
      rating: 5,
      timeLeft: 24,
      totalSales: 6898,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 3,
      name: "Silicon Power 256GB SSD 3D NAND A55...",
      image:
        "https://fakestoreapi.com/img/71kWymZ+c+L._AC_SX679_.jpg",
      category: "electronics",
      price: 5000,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 4,
      name: "WD 4TB Gaming Drive Works with Play...",
      image:
        "https://fakestoreapi.com/img/61mtL65D4cL._AC_SX679_.jpg",
      category: "electronics",
      price: 25000,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 5,
      name: "Acer SB220Q bi 21.5 inches Full HD ...",
      image:
        "https://fakestoreapi.com/img/81QpkIctqPL._AC_SX679_.jpg",
      category: "electronics",
      price: 19999,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 6,
      name: "Samsung 49-Inch CHG90 144Hz Curved ...",
      image:
        "https://fakestoreapi.com/img/81Zt42ioCgL._AC_SX679_.jpg",
      category: "electronics",
      price: 70000,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 7,
      name: "BIYLACLESEN Women's 3-in-1 Snowboar...",
      image:
        "https://fakestoreapi.com/img/51Y5NI-I5jL._AC_UX679_.jpg",
      category: "ladies",
      price: 2599,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 8,
      name: "Lock and Love Women's Removable Hoo...",
      image:
        "https://fakestoreapi.com/img/81XH0e8fefL._AC_UY879_.jpg",
      category: "ladies",
      price: 2599,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 9,
      name: "Rain Jacket Women Windbreaker Strip...",
      image:
        "https://fakestoreapi.com/img/71HblAHs5xL._AC_UY879_-2.jpg",
      category: "ladies",
      price: 3333,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 10,
      name: "MBJ Women's Solid Short Sleeve Boat...",
      image:
        "https://fakestoreapi.com/img/71z3kpMAYsL._AC_UY879_.jpg",
      category: "ladies",
      price: 799,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 11,
      name: "Opna Women's Short Sleeve Moisture...",
      image:
        "https://fakestoreapi.com/img/51eg55uWmdL._AC_UX679_.jpg",
      category: "ladies",
      price: 699,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 12,
      name: "Mens Casual Premium Slim Fit T-Shir...",
      image:
        "https://fakestoreapi.com/img/71-3HjGNDUL._AC_SY879._SX._UX._SY._UY_.jpg",
      category: "gents",
      price: 300,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 13,
      name: "DANVOUY Womens T Shirt Casual Cotto...",
      image:
        "https://fakestoreapi.com/img/61pHAEJ4NML._AC_UX679_.jpg",
      category: "ladies",
      price: 999,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!", 
    },
    {
      id: 14,
      name: "Mens Cotton Jacket...",
      image:
        "https://fakestoreapi.com/img/71li-ujtlUL._AC_UX679_.jpg",
      category: "gents",
      price: 1155,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 15,
      name: "Mens Casual Slim Fit...",
      image:
        "https://fakestoreapi.com/img/71YXzeOuslL._AC_UY879_.jpg",
      category: "gents",
      price: 900,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 16,
      name: "John Hardy Women's Legends Naga Gol...",
      image:
        "https://fakestoreapi.com/img/71pWzhdJNwL._AC_UL640_QL65_ML3_.jpg",
      category: "jewelery",
      price: 6950,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 17,
      name: "Solid Gold Petite Micropave",
      image:
        "https://fakestoreapi.com/img/61sbMiUnoGL._AC_UL640_QL65_ML3_.jpg",
      category: "jewelery",
      price: 7500,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 18,
      name: "White Gold Plated Princess...",
      image:
        "https://fakestoreapi.com/img/71YAIFU48IL._AC_UL640_QL65_ML3_.jpg",
      category: "jewelery",
      price: 3999,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 19,
      name: "Pierced Owl Rose Gold Plated Stainl...",
      image:
        "https://fakestoreapi.com/img/51UDEzMJVpL._AC_UL640_QL65_ML3_.jpg",
      category: "jewelery",
      price: 10999,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    },
    {
      id: 20,
      name: "WD 2TB Elements Portable External H...",
      image:
        "https://fakestoreapi.com/img/61IBBVJvSDL._AC_SY879_.jpg",
      category: "electronics",
      price: 999,
      rating: 4,
      timeLeft: 21,
      totalSales: 6347,
      description: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Magnam veniam rerum nobis, labore itaque, quidem ipsa voluptas alias reprehenderit beatae cum consequuntur illo excepturi natus sequi ex atque optio non perferendis, culpa aperiam iste! Nemo repudiandae magni ducimus laboriosam impedit accusantium nulla nihil assumenda dolor harum qui quaerat labore deserunt adipisci tempora culpa tempore repellat, tenetur est suscipit!",
    }
  ];
}
  export default productData;