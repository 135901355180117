import React, { useEffect, useState } from 'react'
import Banner from '../components/Banner'
import Products from '../components/Products';
import { useLoaderData } from 'react-router-dom';

const MainPage = () => {
  const [products, setProducts] = useState([]);
  const data = useLoaderData();
  
  useEffect(()=> {
    setProducts(data);
  },[data]);

  const slideImage = [
    {
      id: 1,
      img: "https://marketplace.canva.com/EAFGKRRskMs/1/0/1600w/canva-brown-and-beige-minimalist-fashion-banner-lYcbGpUSVGo.jpg",
      title: "Buy Premium Products",
      cta: "SHOP NOW",
    },
    {
      id: 2,
      img: "https://www.blog.indiabazaaronline.com/wp-content/uploads/2016/07/banner3.jpg",
      title: "Get all the Accessories you need",
      cta: "SHOP NOW",
    },
    {
      id: 3,
      img: "https://www.megastudios.com/assets/images/slider/banner.jpg",
      title: "Wide  range of products",
      cta: "SHOP NOW",
    },
  ];

  return (
    <div>
        <Banner slides={slideImage}/>
        <Products products={products}/>
    </div>
  )
}

export default MainPage